import { Box, BoxProps, Flex, HStack, Icon, Text } from "@biblioteksentralen/react";
import { isRepeatedEventOccurence } from "@libry-content/common";
import { Clock } from "react-feather";
import { getPath } from "../../../../utils/getPath";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { ResolvedPaletteColor } from "../../../../utils/useSitePalette";
import EventImageDateBadge from "../../../arrangement/EventImageDateBadge";
import { EventLocation } from "../../../arrangement/EventLocation";
import { EventStatusBadge } from "../../../arrangement/EventStatus";
import { LocationIcon } from "../../../arrangement/LocationIcon";
import { ResolvedEventSummary } from "../../../arrangement/sanityQuery";
import { eventTimeString } from "../../../arrangement/utils";
import SingleItemBanner, { SingleItemBannerImage } from "../SingleItemBanner";

type Props = { event: ResolvedEventSummary; bannerColor: ResolvedPaletteColor } & BoxProps;

export function FeaturedEventBanner({ event, bannerColor, ...boxProps }: Props) {
  const { t, ts } = useTranslation();

  const image = (
    <Box height="100%">
      <Box position="relative" height="100%">
        <SingleItemBannerImage borderRadius="0" image={event.image} />
        <EventImageDateBadge date={event.startDate} />
        <EventStatusBadge event={event} fontSize={{ base: "1xl", sm: "2xl" }} />
      </Box>
    </Box>
  );

  return (
    <SingleItemBanner
      aria-description={t("arrangement")}
      as="aside"
      bannerColor={bannerColor}
      imageSide="right"
      customImageComponent={image}
      seeMore={{ href: getPath(event), label: t("Se arrangementet") }}
      editDocumentProps={{
        _id: isRepeatedEventOccurence(event) ? event.series.id : event._id,
        _type: "event",
      }}
      promotion={event.promotion}
      title={ts(event.title)}
      teaser={ts(event.teaser)}
      extraInfo={
        <>
          <HStack>
            <Icon aria-label={t("tidspunkt")} as={Clock} flexShrink={0} />
            <Flex gap="0 .5rem" flexWrap="wrap">
              <Text fontSize="sm">{event.allDay ? t("Hele dagen") : eventTimeString(event)}</Text>
            </Flex>
          </HStack>
          <HStack>
            <Icon aria-label={t("sted")} as={LocationIcon} flexShrink={0} />
            <EventLocation fontSize="sm" event={event} />
          </HStack>
        </>
      }
      {...boxProps}
    />
  );
}
